import type {
  ApmBase,
  AgentConfigOptions as AgentConfigOptionsRum,
} from '@elastic/apm-rum';
import type {
  Agent,
  AgentConfigOptions as AgentConfigOptionsNode,
} from 'elastic-apm-node';

// Since packages don't depend on @elastic/apm-rum directly, we need to re-export the types
// for better ergonomics.
export { type Transaction, type Span } from '@elastic/apm-rum';

let apm: (ApmBase & Agent) | undefined;

type Options = AgentConfigOptionsRum & AgentConfigOptionsNode;

export const captureError = (
  ...args: Parameters<Agent['captureError'] & ApmBase['captureError']>
) => {
  apm?.captureError?.(...args);
};

export const logger = apm?.logger;

// INFO: Add a simple getter for the APM client instance.
// Relying on the initApm function to get the apm instance is not ideal
// since one would have to feed the parameters again and again.
export const getApm = () => apm;

/**
 * Server side initialization should be done before server start. See example here https://www.elastic.co/guide/en/apm/agent/nodejs/current/nextjs.html#_step_2_start_the_apm_agent_2
 *
 * Client side initialization should done as early as possible, to catch all errors as soon as possible.
 * Preferebly in _app.tsx
 */
export const initApm = async ({
  environment = 'prod',
  logLevel = 'warn',
  serverUrl,
  serverUrlPrefix,
  serviceName,
  serviceVersion,
  ignoreTransactions,
}: {
  environment?: 'dev' | 'test' | 'qa' | 'prod';
  logLevel?: Options['logLevel'];
  serverUrl?: Options['serverUrl'];
  serverUrlPrefix?: Options['serverUrlPrefix'];
  serviceName?: Options['serviceName'];
  serviceVersion?: Options['serviceVersion'];
  ignoreTransactions?: Array<string | RegExp>;
}) => {
  if (apm) return apm;

  if (!serverUrl) return;

  if (typeof document !== 'undefined') {
    const apmAgent = await import('@elastic/apm-rum');
    console.debug(`[elastic-apm]: Initializing Elastic Apm RUM...`);
    // @ts-expect-error
    apm = apmAgent.init({
      environment,
      logLevel,
      serverUrl,
      serverUrlPrefix,
      serviceName,
      serviceVersion,
      ignoreTransactions,
    });
  } else {
    const { default: apmDefault } = await import('elastic-apm-node');
    console.debug(`[elastic-apm]: Initializing Elastic Apm NODE...`);
    // @ts-expect-error
    apm = apmDefault;
  }
};
