'use client';

import { type ReactNode, useEffect, useState } from 'react';
import { initApm } from '@vcc-www/elastic-apm';
import { getApmVariablesFromPublicConfig } from '@vcc-www/elastic-apm/getApmVariablesFromPublicConfig';
import { PreviewMode } from '@components/preview-mode/preview-mode.module';
import { PREVIEW_QUERY_PARAMS } from '@config';
import { ClientSideRuntimeVariables } from '@contexts/client-side-runtime-variables/client-side-runtime-variables.context';
import { DictionariesProvider } from '@contexts/dictionaries/dictionaries.provider';
import {
  type FeatureFlags,
  FeatureFlagsProvider,
} from '@contexts/feature-flags';
import { GlobalErrorProvider } from '@contexts/global-error/global-error.provider';
import { GlobalLoadingProvider } from '@contexts/global-loading/global-loading.provider';
import { GlobalModalProvider } from '@contexts/global-modal/global-modal.provider';
import { getPublicEnv, getStyleRenderer } from '@utils';
import { isValidTestSearchParamTriggerVal } from '@utils/search-param-valid-trigger-val';
import { SharedComponentsDictionariesProvider } from '@vcc-www/shared-dictionaries';
import { urlFromString, VolvoCarsUrlProvider } from '@vcc-www/volvo-cars-url';
import { FlattenedDictionaries } from '@volvo-cars/content-delivery-client/dictionaries/types';
import { links } from '@volvo-cars/css/links';
import { getMarketSite } from '@volvo-cars/market-sites';
import {
  usePathname,
  useSearchParams,
  useServerInsertedHTML,
} from 'next/navigation';
import type { PageParams } from 'src/types/page-search-params';
import { ConfigProvider, StyleProvider, StyleTags, ThemePicker } from 'vcc-ui';
import { ClientSideRuntimeVariablesProvider } from '../../src/contexts/client-side-runtime-variables/client-side-runtime-variables.provider';

export const LayoutProviderWrapper = ({
  children,
  clientSideRuntimeVariables,
  dictionaries,
  featureFlags,
  pageParams,
  sharedDictionaries,
}: {
  children: ReactNode;
  clientSideRuntimeVariables: ClientSideRuntimeVariables;
  dictionaries: FlattenedDictionaries;
  featureFlags: FeatureFlags | null;
  pageParams: PageParams;
  sharedDictionaries: FlattenedDictionaries;
}) => {
  // Initialise the Elastic APM client
  initApm(getApmVariablesFromPublicConfig(getPublicEnv()));

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [routeChangeHandler, setRouteChangeHandler] = useState<VoidFunction>();

  // Update VolvoCarsUrl when the route changes. VolvoCarsUrl by default listens
  // to click events only which is too early some times
  useEffect(() => {
    if (!routeChangeHandler) return;
    routeChangeHandler();
  }, [pathname, searchParams, routeChangeHandler]);

  const url = urlFromString(pathname || '');
  const { locale, languageDirection } = getMarketSite(url.siteSlug);

  const [styleRenderer] = useState(() => {
    return getStyleRenderer();
  });

  useServerInsertedHTML(() => {
    return (
      <>
        <StyleTags renderer={styleRenderer} />
        {links().map((link, i) => (
          <link key={link.href + i} {...link} />
        ))}
      </>
    );
  });

  return (
    <ClientSideRuntimeVariablesProvider {...clientSideRuntimeVariables}>
      <ConfigProvider config={{ locale }}>
        <StyleProvider renderer={styleRenderer}>
          <ThemePicker direction={languageDirection}>
            <VolvoCarsUrlProvider
              url={urlFromString(pathname || '')}
              setRouteChangeHandler={setRouteChangeHandler}
            >
              <FeatureFlagsProvider featureFlags={featureFlags}>
                <DictionariesProvider
                  dictionaries={dictionaries}
                  isDictionariesModeActive={isValidTestSearchParamTriggerVal(
                    pageParams[PREVIEW_QUERY_PARAMS.dictionaries],
                  )}
                >
                  <SharedComponentsDictionariesProvider
                    locale={locale}
                    dictionaries={sharedDictionaries}
                  >
                    <GlobalModalProvider>
                      <PreviewMode
                        isPreviewModeActive={
                          isValidTestSearchParamTriggerVal(
                            pageParams[PREVIEW_QUERY_PARAMS.preview],
                          ) && clientSideRuntimeVariables.DEPLOY_ENV !== 'prod'
                        }
                      >
                        <GlobalErrorProvider>
                          <GlobalLoadingProvider>
                            {children}
                          </GlobalLoadingProvider>
                        </GlobalErrorProvider>
                      </PreviewMode>
                    </GlobalModalProvider>
                  </SharedComponentsDictionariesProvider>
                </DictionariesProvider>
              </FeatureFlagsProvider>
            </VolvoCarsUrlProvider>
          </ThemePicker>
        </StyleProvider>
      </ConfigProvider>
    </ClientSideRuntimeVariablesProvider>
  );
};
