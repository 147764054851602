import nextConfig from 'next/config';

export function getApmVariablesFromPublicConfig(config?: Record<any, any>) {
  const variables = config || (nextConfig() || {}).publicRuntimeConfig;
  return {
    environment: variables?.elasticEnvironment,
    logLevel: variables?.elasticLogLevel,
    serverUrl: variables?.elasticServerUrl,
    serviceName: variables?.elasticServiceName,
    serviceVersion: variables?.elasticServiceVersion,
    serviceApiKey:
      variables &&
      ('elasticServiceApiKey' in variables
        ? variables?.elasticServiceApiKey
        : undefined),
  };
}
