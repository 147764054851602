'use client';
import React from 'react';
import Snowfall from 'react-snowfall';

export function SnowfallComponent() {
  return (
    <Snowfall
      speed={[2, 2]}
      snowflakeCount={500}
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
      }}
    />
  );
}
